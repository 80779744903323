import { Link, navigate, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import styled from "styled-components"
import BookDetails from "./BookDetails"
import {
  ScFlexContainer,
  ScGridRow,
  ScGridCol,
} from "../StyledComponents/elements"

function BookItem(props) {
  const { BookImg, title, description, url } = props
  return (
    <ScGridRow alignItems="center">
      <ScGridCol span={6}>
        <BookImg />
      </ScGridCol>

      <ScGridCol span={6}>
        <BookDetails url={url} title={title} description={description} />
      </ScGridCol>
    </ScGridRow>
  )
}

export default BookItem
