import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import BookItem from "../components/common/BookItem"
import PortalImg from "../components/common/PortalImg"
import MuckleminsImg from "../components/common/MuckleminsImg"
import BlueGradientImage from "../components/common/BlueGradientImage"
import {
  ScGridRow,
  ScGridCol,
  ScH1,
  ScButton,
  ScExternalLinkButton,
  ScPageWrapper,
} from "../components/StyledComponents/elements"
import PortalScottImg from "../components/common/PortalScottImg"

const IndexPage = props => {
  console.log(props)

  return (
    <section>
      <SEO title="Home" />
      <ScPageWrapper>
        <ScGridRow justifyContent="center" alignItems="center">
          <ScGridCol span={6}>
            <BookItem
              url="/portal-in-the-park"
              title="The Portal in the Park"
              description="Revolutionary mental fitness book/audiobook fully narrated by Grandmaster Melle Mel. “The Portal in the Park,” has 7 inspirational songs written and performed by Grand Master Melle Mel and features Lady Gaga."
              BookImg={PortalImg}
            ></BookItem>
          </ScGridCol>
          <ScGridCol span={6}>
            <BookItem
              url="/muklemins"
              title="The Mucklemins: Camping In"
              description="Kids…take a break from technology! Sing and dance with the Muklemin children—Chloe and Zoe are identical twins.  Chloe loves ballet and dressing up like a princess.  Zoe is a fashionista who loves to re-design her clothes.  Their big brother, Ace, is full of ideas and loves to sing."
              BookImg={MuckleminsImg}
            ></BookItem>
          </ScGridCol>
        </ScGridRow>
      </ScPageWrapper>
      <BlueGradientImage>
        <ScGridRow justifyContent="center">
          <ScH1>Login On Your Noggin Channel</ScH1>
        </ScGridRow>
        <ScGridRow justifyContent="center" style={{ padding: "0 10px" }}>
          {/* <PortalScottImg /> */}
          <iframe
            width="100%"
            height="315"
            style={{ maxWidth: "560px" }}
            src="https://www.youtube.com/embed/wJwidLYD8Zs"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </ScGridRow>
        <ScGridRow justifyContent="center">
          <ScExternalLinkButton
            href="https://www.youtube.com/channel/UCQuyh9KU03VeLXUwf5JeY6A"
            color="primary"
          >
            Visit The Youtube Channel
          </ScExternalLinkButton>
        </ScGridRow>
      </BlueGradientImage>
      {/* {props.data.allBook.edges.map(edge => (
        <div key={edge.node.id}>
          <h2>{edge.node.title}</h2>
          <h2>{edge.node.author.name}</h2>
          <div>{edge.node.summary}</div>
          <Link to={`/book/${edge.node.id}`}>Join conversation</Link>
        </div>
      ))} */}
    </section>
  )
}

// export const query = graphql`
//   {
//     allBook {
//       edges {
//         node {
//           summary
//           title
//           localImage {
//             childImageSharp {
//               fixed(width: 200) {
//                 ...GatsbyImageSharpFixed
//               }
//             }
//           }
//           id
//           author {
//             name
//           }
//         }
//       }
//     }
//   }
// `

export default IndexPage
