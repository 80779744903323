import React from "react"
import { ScH3, ScText, ScLinkButton } from "../StyledComponents/elements"
import styled from "styled-components"
const DescriptionWrapper = styled.div`
  @media only screen and (max-width: 960px) {
    padding: 10px;
  }
`
function BookDetails({ title, description, url }) {
  return (
    <DescriptionWrapper>
      <ScH3>{title}</ScH3>
      <ScText
        style={{ paddingTop: "20px" }}
        fontSize="data-text"
        color="primary"
      >
        {description}
      </ScText>
      <ScLinkButton to={url} style={{ marginTop: "20px" }} color="primary">
        Learn More
      </ScLinkButton>
    </DescriptionWrapper>
  )
}

export default BookDetails
