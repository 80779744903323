import { graphql } from "gatsby"
import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Bg from "./Bg"
import Background from "../../images/blue_background_gradient.jpg"
const BackgroundImage = styled.div`
  padding-top: 100px;
  width: 100%;
`
function BlueGradientImage({ children }) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { regex: "/blue_background_gradient.jpg/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      title="Gradient"
      style={{ backgroundImage: `url(${Background})` }}
      // fluid={data.placeholderImage.childImageSharp.fluid}
    >
      {children}
    </BackgroundImage>
  )
}

export default BlueGradientImage
