import { graphql } from "gatsby"
import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ScHeaderImage = styled(Img)`
  max-width: 550px;
  width: 100%;
`

function PortalScottImg() {
  const portalImg = useStaticQuery(graphql`
    {
      fileName: file(
        relativePath: { eq: "scott_hanging_from_portal_sign.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <ScHeaderImage fluid={portalImg.fileName.childImageSharp.fluid} />
}

export default PortalScottImg
