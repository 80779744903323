import { graphql } from "gatsby"
import React, { useContext } from "react"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ScHeaderImage = styled(Img)`
  max-width: 700px;
  width: 100%;
`

function PortalImg() {
  const portalImg = useStaticQuery(graphql`
    {
      fileName: file(relativePath: { eq: "portal_book.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <ScHeaderImage fluid={portalImg.fileName.childImageSharp.fluid} />
}

export default PortalImg
